import { NotFound } from "@/screens/not-found/NotFound";

import "../tailwind.css";

export async function loader() {
  throw new Response(null, {
    status: 404,
  });
}

export default function () {
  return null;
}

export function ErrorBoundary() {
  return <NotFound />;
}
